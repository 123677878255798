exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-art-js": () => import("./../../../src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-hello-js": () => import("./../../../src/pages/hello.js" /* webpackChunkName: "component---src-pages-hello-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-see-js": () => import("./../../../src/pages/see.js" /* webpackChunkName: "component---src-pages-see-js" */),
  "component---src-pages-visit-js": () => import("./../../../src/pages/visit.js" /* webpackChunkName: "component---src-pages-visit-js" */),
  "component---src-templates-art-template-js": () => import("./../../../src/templates/art-template.js" /* webpackChunkName: "component---src-templates-art-template-js" */),
  "component---src-templates-see-template-js": () => import("./../../../src/templates/see-template.js" /* webpackChunkName: "component---src-templates-see-template-js" */),
  "component---src-templates-visit-template-js": () => import("./../../../src/templates/visit-template.js" /* webpackChunkName: "component---src-templates-visit-template-js" */)
}

